import React, { useState, useEffect, useRef } from 'react';
import { shape } from 'prop-types';

import classnames from 'classnames';
import Tag from '@andes/tag';
import CarouselFree, { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../components-v2/link';

const namespace = 'ui-search-filter-intervention';

const TextCarousel = ({ filter }) => {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      const element = ref.current.querySelectorAll(`.${namespace}__link`)[filter.scroll_position || 0];

      if (element) {
        element.scrollIntoView({ block: 'nearest', inline: 'center' });
      }

      setLoading(false);
    }
  }, [filter.scroll_position]);

  return (
    <div ref={ref}>
      <CarouselFree spacing={12} className={`${namespace}-carousel`}>
        {filter.values.map((slide) => (
          <CarouselFreeSlide key={slide.id} className={`${namespace}-text-item-slide`}>
            <Link className={`${namespace}__link`} href={slide.target} title={slide.label.text}>
              <Tag className={classnames({ skeleton: loading })} label={slide.label.text} />
            </Link>
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </div>
  );
};

TextCarousel.propTypes = {
  filter: shape().isRequired,
};

export default TextCarousel;
