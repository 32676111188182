import React from 'react';
import { shape } from 'prop-types';

import Tag from '@andes/tag';
import CarouselFree, { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../components-v2/link';

const namespace = 'ui-search-filter-intervention';

const ColorCarousel = ({ filter }) => (
  <CarouselFree spacing={16} className={`${namespace}-carousel`}>
    {filter.values.map((slide) => (
      <CarouselFreeSlide key={slide.id} className={`${namespace}-color-item-slide`}>
        <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.label.text}>
          <Tag asset={{ text: ' ' }} label={slide.label.text} className={`${namespace}-tag-color-${slide.value_id}`} />
        </Link>
      </CarouselFreeSlide>
    ))}
  </CarouselFree>
);

ColorCarousel.propTypes = {
  filter: shape().isRequired,
};

export default ColorCarousel;
